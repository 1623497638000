<template>
    <div class="apitoken-container" v-loading="loading">
        <el-divider content-position="left">大屏信息</el-divider>
        <el-form
            ref="form"
            :model="bigDataDetail"
            class="geology-form"
            label-width="120px"
            :rules="formRules"
        >
            <el-form-item label="访问地址:">
                <a
                    :href="'/raydata/show?bigcode=' + bigDataDetail.BigCode"
                    target="_blank"
                >
                    <el-button type="primary">访问大屏</el-button>
                </a>
            </el-form-item>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="标题:" prop="Title">
                        <el-input
                            v-model="bigDataDetail.Title"
                            placeholder="请输入标题信息"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="文字大小:" prop="FontSize">
                        <el-input
                            v-model="bigDataDetail.FontSize"
                            placeholder="文字大小"
                            style="margin-left: 10px"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="省份:" prop="Province">
                        <el-input
                            v-model="bigDataDetail.Province"
                            placeholder="请输入所在省份"
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="大屏地图:" prop="MapAreaId">
                        <el-select v-model="bigDataDetail.MapAreaId"
                            style="margin-left: 10px">
                            <el-option v-for="item in bigAreaData"
                                :key="item.Id"
                                :label="item.AreaName"
                                :value="item.Id">
                            </el-option>
                        </el-select>
                        
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="坐标:" prop="Coordinate">
                    <el-col :span="10">
                        <el-input
                            v-model="Coordinate"
                            placeholder="请输入经纬度 116.41360749,39.9102069"
                        ></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="描述:">
                    <el-col :span="10">
                        <el-input
                            type="textarea"
                            rows="5"
                            v-model="bigDataDetail.Desc"
                            placeholder="请输入描述信息"
                            resize="none"
                        ></el-input>
                    </el-col>
                </el-form-item>

                <el-col :span="23">
                    <el-form-item>
                        <el-button
                            type="primary"
                            size="medium"
                            @click="onSubmit"
                            >立即保存</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import { getBigData, setBigData, getBigAreaData } from "@/api/api";
export default {
    data() {
        var validateCoordinate = (rule, value, callback) => {
            if (!this.Coordinate) {
                callback(new Error("请输入经纬度"));
            }
        };
        return {
            loading: false,
            Coordinate: "",
            bigAreaData:[],
            bigDataDetail: {
                BigCode: "",
                Title: "",
                Desc: "",
                MapAreaId:"",
                Province: "",
                Longitude: "",
                Latitude: "",
                FontSize: "36",
                Id: 0,
            },
            formRules: {
                Title: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入标题信息",
                    },
                ],
                Province: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入所在省份",
                    },
                ],
                Coordinate: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入经纬度",
                        validator: validateCoordinate,
                    },
                ],
            },
        };
    },
    methods: {
        onSubmit() {
            var longlat = this.Coordinate.split(",");
            this.bigDataDetail.Longitude = longlat[0];
            this.bigDataDetail.Latitude = longlat[1];
            setBigData(this.bigDataDetail)
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("保存成功!");
                    } else {
                        this.$message.error("保存失败!" + res.msg);
                    }
                })
                .catch((errmsg) => {
                    this.$message.error("保存失败!" + errmsg);
                });
        },
    },
    created() {
        this.loading = true;
        getBigAreaData().then(res=>{
            if(res.code==0){
                this.bigAreaData = res.data;
            }
            
            getBigData()
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.bigDataDetail = res.data;
                        this.Coordinate =
                            res.data.Longitude + "," + res.data.Latitude;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.$message.error("网络连接失败，请刷新重试");
                    this.loading = false;
                });
        })
    },
};
</script>